<template>
  <div class="page terms">
    <h1 class="center">Algemene voorwaarden</h1>
    <h2>Artikel 1. Toepassing</h2>
    <ol>
      <li>Onderhavige voorwaarden gelden voor alle overeenkomsten ter zake van koop, verkoop, levering en betaling welke
        zijn gesloten tussen Bio Degradable Packaging BV en de Klant. Deze algemene voorwaarden hebben voorrang op de
        eigen voorwaarden van de Klant.
      </li>
      <li>De algemene voorwaarden worden op de website en bij iedere factuur ter beschikking gesteld aan de Klant ofwel
        door een hyperlink ofwel in bijlage via mail. Een kopie van de algemene voorwaarden kunnen telkens kosteloos
        verkregen worden. De Klant wordt geacht kennis te hebben genomen van de algemene voorwaarden die hem of haar
        worden overgemaakt bij de orderbevestiging. Indien er geen protest plaatsvindt, wordt de Klant geacht
        stilzwijgend met deze algemene voorwaarden akkoord te zijn gegaan.
      </li>
      <li>Er kan uitdrukkelijk worden afgeweken van de algemene voorwaarden. Deze afwijkingen gebeuren in onderling
        overleg en worden steeds schriftelijk vastgelegd. Dit geschrift moet ondertekend worden door de Zaakvoerder van
        Bio Degradable Packaging BV. Dergelijke afwijkingen kunnen bestaan in de wijziging, aanvulling of schrapping van
        de bepalingen waarop ze betrekking hebben en laten de toepassing van de overige bepalingen van deze algemene
        voorwaarden onverlet.
      </li>
      <li>Alvorens de overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden aan de Klant
        beschikbaar gesteld. Indien de overeenkomst elektronisch op afstand wordt gesloten, dan wordt de tekst langs
        elektronische weg ter beschikking gesteld.
      </li>
      <li>De Ondernemer behoudt zich het recht deze algemene voorwaarden te allen tijde en zonder uitdrukkelijke
        kennisgeving aan te passen. De algemene voorwaarden die van toepassing zijn, zijn de gepubliceerde voorwaarden
        op datum van de orderbevestiging.
      </li>
    </ol>
    <h2>Artikel 2. Definities</h2>
    <ol>
      <li><b>Elektronische drager:</b> Dit is o.a. een e-mail, een gsm-bericht, een faxbericht en elke andere vorm van
        overdracht van informatie via een elektronisch toestel.
      </li>
      <li><b>Factuurdatum:</b> De dag waarop de factuur werd verzonden.</li>
      <li><b>Gedeeltelijke levering:</b> Een gedeeltelijke levering kan, naast het annuleren door de Klant zelf, een
        andere oorzaak hebben: Een of meerdere goederen van de bestelling hebben vertraging opgelopen en er is in
        wederzijds overleg besloten om het resterend gedeelte van de bestelling alvast te leveren. De Klant wordt
        hiervan op de hoogte gebracht. In dit geval wordt het resterend gedeelte na geleverd.
      </li>
      <li><b>Klant:</b> De handelaar en/of natuurlijke persoon die handelt met de Ondernemer.</li>
      <li><b>Offerte:</b> Is een vrijblijvend aanbod door de Ondernemer.</li>
      <li><b>Ondernemer:</b> De besloten vennootschap Bio Degradable Packaging BV met maatschappelijke zetel te
        Wiedauwkaai 98, 9000 Gent, België met ondernemingsnummer 0886.412.625.
      </li>
      <li><b>Zaakvoerder:</b> De natuurlijke persoon Andy Verbauwhede.</li>
    </ol>
    <h2>Artikel 3. Offertes</h2>
    <ol>
      <li>Alle Offertes uitgegeven door de Ondernemer zijn vrijblijvend. De Ondernemer heeft te allen tijde het recht om
        opdrachten te weigeren. Er wordt in de Offerte een vaste prijs begroot voor de goederen.
      </li>
      <li>Offertes opgesteld door de Ondernemer zijn steeds persoonlijk gericht tot de Klant en vallen onder
        vertrouwelijke informatie. Het is bijgevolg niet toegestaan om de Offertes zonder toestemming van de Ondernemer
        aan derden mee te delen of beschikbaar te stellen.
      </li>
      <li>Aanbiedingen en Offertes gelden niet automatisch voor toekomstige opdrachten. De Ondernemer behoudt zich het
        recht zijn prijzen te allen tijde te wijzigen bij toekomstige nieuwe overeenkomsten met de Klant.
      </li>
    </ol>
    <h2>Artikel 4. Aanvang overeenkomst</h2>
    <ol>
      <li>De overeenkomst vangt aan op het moment dat er overeenstemming is tussen Klant en Ondernemer met betrekking
        tot de prijs en het voorwerp van de overeenkomst.
      </li>
      <li>De overeenkomst komt tot stand wanneer de Klant de bestelling heeft geplaatst en de Ondernemer de Klant het
        order per Elektronische drager heeft bevestigd. Zolang de ontvangst van het order niet door de Ondernemer is
        bevestigd, kan de Klant de overeenkomst ontbinden.
      </li>
    </ol>
    <h2>Artikel 5. Gedeeltelijke levering</h2>
    <ol>
      <li>De Ondernemer behoudt zich het recht voor Gedeeltelijke leveringen te doen. De Gedeeltelijke levering van een
        bestelling kan de weigering van tot betaling van de geleverde goederen niet rechtvaardigen.
      </li>
    </ol>
    <h2>Artikel 6. Levering</h2>
    <ol>
      <li>Leveringstermijnen in het kader van overeenkomsten met de Ondernemer zijn slechts indicatieve termijnen die de
        Ondernemer niet uitdrukkelijk binden tenzij dit expliciet is overeengekomen tussen de partijen. Vertragingen op
        deze termijnen geven niet automatisch recht op enige vorm van schadevergoeding of op beëindiging van de
        overeenkomst. Levering buiten de gestelde termijn kan slechts door wederzijds akkoord het geheel of gedeeltelijk
        annuleren van een order tot gevolg hebben.
      </li>
      <li>Uitdrukkelijke leveringstermijnen die de Ondernemer binden worden steeds schriftelijk overeengekomen tussen
        partijen.
      </li>
      <li>Leveringstermijnen vangen aan de dag na het sluiten van deze schriftelijke overeenkomst.</li>
      <li>Indien de levering aan de overeengekomen termen en voorwaarden onmogelijk wordt, behoudt de Ondernemer zich
        het recht voor om de overeenkomst te annuleren en zonder dat de koper aanspraak kan maken op enige
        schadevergoeding.
      </li>
      <li>In geval van een openstaand saldo niet-betaalde facturen op naam van de Klant, heeft de Ondernemer het recht,
        na ingebrekestelling, hetzij eventuele toekomstige leveringen op te schorten, hetzij de overeenkomst te
        ontbinden, onverminderd het recht op schadevergoeding.
      </li>
    </ol>
    <h2>Artikel 7. Betaling</h2>
    <ol>
      <li>Alle facturen dienen betaald te worden uiterlijk dertig dagen na de Factuurdatum. Een betaaltermijn die dertig
        dagen overschrijdt, dient schriftelijk overeen te worden gekomen met expliciete goedkeuring door de onderneming
        en de zaakvoerder.
      </li>
      <li>De betaling dient steeds te gebeuren bij wijze van overschrijving, tenzij men in de overeenkomst schriftelijk
        anders is overeengekomen. De betaling gebeurt op het volgend rekeningnummer: BE79 7360 6919 6733
      </li>
      <li>In het kader van de overeenkomsten gesloten met de Ondernemer wordt schuldvergelijking steeds uitgesloten.
      </li>
      <li>De Klant kan tot uiterlijk tien dagen na de Factuurdatum klachten per aangetekende brief op de
        maatschappelijke zetel van de Ondernemer versturen. Na deze termijn worden de facturen geacht aanvaard te zijn.
      </li>
    </ol>
    <h2>Artikel 8. Eigendomsvoorbehoud</h2>
    <ol>
      <li>De Ondernemer blijft eigenaar van de goederen tot de Klant het volledige factuurbedrag aan de Ondernemer heeft
        overgemaakt. Eventuele gedeeltelijke betaling brengt geenszins een proportionele eigendomsoverdracht met zich
        mee.
      </li>
      <li>De betaalde voorschotten blijven verworven voor de Ondernemer ter vergoeding van de mogelijke verliezen bij
        wederverkoop van de goederen.
      </li>
    </ol>
    <h2>Artikel 9. Schadebedingen</h2>
    <ol>
      <li>Als de Klant niet tijdig overgaat tot betaling van de factuur, wordt door de Ondernemer een aanmaning tot
        betaling verstuurd. De kosten van aanmaning worden voor de totale som verhaald op de Klant.
      </li>
      <li>Elk bedrag dat onbetaald blijft op zijn vervaldag, zal van rechtswege en zonder ingebrekestelling rente geven
        berekend op basis van de Belgische wettelijke intrestvoet vermeerderd met 2% met een minimum intrestvoet van
        12%.
      </li>
      <li>In geval van gehele of gedeeltelijke niet-betaling van de schuld op de vervaldag zonder ernstige redenen,
        wordt na ingebrekestelling, het schuldsaldo verhoogd met 15% met een minimum van 125,00 euro zelfs bij
        toekenning van termijnen van respijt.
      </li>
    </ol>
    <h2>Artikel 10. Aansprakelijkheid</h2>
    <ol>
      <li>De Ondernemer maakt voor bepaalde overeenkomsten gebruik van de diensten van een derde-leverancier. Bijgevolg
        is de Ondernemer nooit aansprakelijk voor enige wanprestatie door deze derde-leverancier.
      </li>
      <li>De vastheid der inktkleuren wordt niet gewaarborgd. Een afwijking mag nooit de weigering van de koopwaren
        rechtvaardigen noch de vraag naar prijsvermindering. De tinten en de zuiverheid van het papier kunnen
        verschillen van de ene fabricatie tot de andere. De Ondernemer verbindt zich er niet toe steeds papier van
        dezelfde tint, noch met dezelfde zuiverheid als deze van de stalen te leveren.
      </li>
      <li>Een gering verschil van maximum 10% in de vorm, grootte, kwaliteit of dikte van het product rechtvaardigt
        geenszins de niet-betaling of weigering van het product.
      </li>
      <li>Vele producten uit het gamma van Ondernemer worden vervaardigd uit o.m. gerecycleerd papier, wat eventuele
        afwijkingen ten opzichte van andere producten kan inhouden. De Klant erkent hiervan op de hoogte te zijn.
      </li>
      <li>Bij de fabricatie van producten uit het gamma van Ondernemer zal een hoeveelheid van 2% slechte exemplaren dus
        als normaal beschouwd worden. Bovendien zal een afwijking van maximaal 10% op de bestelde hoeveelheden steeds
        worden aanvaard door de Klant. Deze afwijking wordt opgetrokken naar maximaal 20% indien het gaat om
        bestellingen van minder dan vijfentwintigduizend stuks of tweehonderdvijftig kilogram.
      </li>
      <li>De Ondernemer is echter niet aansprakelijk voor de gevolgen van gebeurtenissen bij de derde-leverancier waarop
        de Ondernemer geen invloed kan uitoefenen.
      </li>
      <li>De Ondernemer kan op geen enkele wijze door de Klant aansprakelijk gesteld worden voor schade, van welke aard
        dan ook door wie dan ook geleden, voortvloeiende uit of in verband staande met de door de Ondernemer geleverde
        of ter beschikking gestelde producten of diensten, tenzij deze schade te wijten is aan opzet of zware fout. De
        Ondernemer is niet aansprakelijk voor lichte fouten.
      </li>
      <li>Ingeval van een vastgesteld en door de Klant bewezen verborgen gebrek wordt de aansprakelijkheid van de
        Ondernemer beperkt tot het vervangen van de gebrekkige goederen met uitsluiting van elke andere vergoeding.
      </li>
    </ol>
    <h2>Artikel 11. Overmacht</h2>
    <ol>
      <li>In geval van overmacht, zowel van blijvende als tijdelijke aard, is Ondernemer gerechtigd de overeenkomst
        geheel of gedeeltelijk te ontbinden of tijdelijke op te schorten zonder dat de wederpartij en/of derden
        aanspraak op nakoming en/of schadevergoeding kunnen doen gelden.
      </li>
      <li>Onder overmacht wordt onder meer, doch niet uitsluitend verstaan: stakingen, boycot, bedrijfsstoring,
        storingen in het verkeer of transport, schaarste aan grondstoffen, brand, machinebreuk, zeer slechte
        weersomstandigheden en overigens alle omstandigheden, waaronder gehele of gedeeltelijke nakoming van de
        overeenkomst naar redelijkheid en billijkheid niet van Ondernemer kan worden verlangd.
      </li>
      <li>Indien door overmacht de levering meer dan twee maanden wordt vertraagd, is naast Ondernemer ook de Klant
        bevoegd om de overeenkomst schriftelijk te ontbinden. In dat geval heeft Ondernemer recht op vergoeding van de
        door hem gemaakte kosten.
      </li>
      <li>Indien overmacht intreedt terwijl de overeenkomst reeds ten dele is uitgevoerd, heeft de Klant, indien door
        overmacht de resterende levering meer dan twee maanden wordt vertraagd, de bevoegdheid om hetzij het reeds
        afgeleverde gedeelte van de zaken te behouden en de daarvoor verschuldigde koopsom te voldoen, hetzij de
        overeenkomst ook voor het reeds uitgevoerde gedeelte als ontbonden te beschouwen, onder de verplichting al
        hetgeen hem reeds geleverd was naar Ondernemer in natura terug te zenden voor rekening en risico van de Klant,
        indien de Klant kan aantonen, dat het reeds afgeleverde gedeelte van de zaken door de Klant niet meer
        doeltreffend kan worden gebruikt ten gevolge van het niet afleveren van de resterende zaken.
      </li>
    </ol>
    <h2>Artikel 12. Klachten</h2>
    <ol>
      <li>Om geldig te zijn moeten alle klachten ons medegedeeld worden bij aangetekend schrijven en binnen de acht
        dagen na ontvangst van de goederen.
      </li>
    </ol>
    <h2>Artikel 13. Kwaliteitsverschillen</h2>
    <ol>
      <li>Kleine afwijking in kleur van het papier en lettertype en/of kleur bij het bedrukken geven geen recht tot
        enige vorm van schadevergoeding.
      </li>
    </ol>
    <h2>Artikel 14. Clichés</h2>
    <ol>
      <li>De kosten van clichés en stempels worden aan de Klant doorberekend. Niettegenstaande deze doorberekening
        worden in de vorige zin genoemde goederen nimmer eigendom van de Klant, tenzij schriftelijk anders
        overeengekomen.
      </li>
    </ol>
    <h2>Artikel 15. Annulering</h2>
    <ol>
      <li>De Klant is niet bevoegd een aan de Ondernemer verstrekte opdracht te annuleren, behoudens schriftelijke
        toestemming van de Ondernemer en Zaakvoerder. De Ondernemer heeft het recht annuleringskosten aan de Klant in
        rekening te brengen, onverminderd het recht van de Ondernemer om volledige schadevergoeding te vorderen. De
        annuleringskosten hebben in ieder geval betrekking op de reeds door de Ondernemer gemaakte voorbereidings- en
        materiaalkosten en administratieve kosten.
      </li>
    </ol>
    <h2>Artikel 16. Nietigheid</h2>
    <ol>
      <li>Indien enige bepaling van deze algemene voorwaarden naar oordeel van de bevoegde rechtbank nietig is, dan zal slechts de betreffende bepaling als ongeschreven beschouwd worden en blijven de overige bepalingen onverlet in werking. De partijen zullen de nietige bepaling vervangen door een andere bepaling die het doel en de strekking van de nietige bepaling zoveel mogelijk benadert.</li>
    </ol>
    <h2>
      Artikel 17. Toepasselijk recht
    </h2>
    <ol>
      <li>Uitsluitend het Belgisch recht is van toepassing op de overeenkomsten gesloten met de Ondernemer.</li>
      <li>Alle geschillen tussen de partijen omtrent overeenkomsten die aan deze algemene voorwaarden zijn onderworpen, behoren uitsluitend tot de bevoegdheid van de Ondernemingsrechtbank Gent, afdeling Gent (Opgeëistenlaan 401/E, 9000 Gent)</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>